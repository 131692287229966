import React from 'react'
import SimpleTemplateWrapper from '../../components/04-templates/t-simple-template-wrapper'
import OPasswordResetForm from '../../components/03-organisms/o-password-reset-form.js'

import APaper from '../../components/01-atoms/a-paper'

const ResetPasswordPage = () => (
  <SimpleTemplateWrapper showHeadlineBar headline="Passwort zurücksetzen">
    <APaper>
      <OPasswordResetForm />
    </APaper>
  </SimpleTemplateWrapper>
)

export default ResetPasswordPage
