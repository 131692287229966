import React from 'react'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'

import { intlIdToString } from '../00-utils/u-translation'
import AButton from '../01-atoms/a-button.js'
import AInput from '../01-atoms/a-input.js'
import AForm from '../01-atoms/a-form'
import { connect } from 'react-redux'
import { passwordReset } from '../../actions'

class OResetPasswordForm extends AForm {
  afterSubmit() {
    this.props.passwordReset(this.getValues(false))
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={this.state.formValid ? '' : 'has-error'}
      >
        <Grid container spacing={3} justify="center">
          <Grid item xs={12}>
            <p>
              <FormattedMessage id="resetPasswordMessage" />
            </p>
          </Grid>
          <Grid item xs={12}>
            <AInput
              error={this.fieldHasError('login')}
              label={intlIdToString('email')}
              name="login"
              onChange={this.handleChange.bind(this, ['isValidMail'])}
              onEnter={name => this.fieldRegister(name)}
              required
              type="email"
              value={
                this.state.fields.login !== undefined
                  ? this.state.fields.login.value
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <br />
            <AButton variant="contained" type="submit">
              {intlIdToString('resetPassword')}
            </AButton>
          </Grid>
        </Grid>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: {
      apiResults: state.apiResults,
      currentlySending: state.currentlySending,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    passwordReset: data => dispatch(passwordReset(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OResetPasswordForm)
